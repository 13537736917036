@import 'prismjs/themes/prism-coy.css';


.p-sortable-column-icon {
    padding: 2px;
    background-color: #ADD8E6;
    border-radius: 2px;
}

.p-tabview {
    width: 100%;
}

.col{
    background-color: white;
}
.p-float-label-input-text {
    width: 100%;
}

.error-border {
    border: 1px solid red !important;

}

.p-field {
    font-weight: bolder;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.p-float-label label {
    color: black;
    font-size: 15px !important;
    margin-bottom: 1rem !important;
}


.menu{
    padding-top: 60px;
    background-color: white;
    margin:0px;
}
.layout-wrapper .layout-main{
    padding:5px
}
#ConcessionsGateNow {

    .gate-now-datatable {
        overflow: auto;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead {
        overflow: auto !important;
        /* Enable scrolling for the header */
        z-index: 1;
        /* Ensure the header stays above the body */
        position: sticky;
        top: 0;
        /* Stick it to the top of the container */
        background-color: white;
        /* Optional: Ensure the background color is consistent */
    }

    .p-datatable .p-column-header-content {
        display: block;
    }

    .p-fluid .p-dropdown {
        display: inline-flex
    }

    .row-red {
        background-color: #ffcccc;
        /* Light red background */
    }

    .p-dropdown {
        max-width: 245px;
    }

    .p-datatable .p-datatable-thead>tr>th {
        padding: 1rem 0.3rem;
        text-align: center;
    }

    .fa-badge-check:before,
    .fa-circle-check:before {
        color: green;
    }

    .fa-circle-xmark:before,
    .fa-times-circle:before,
    .fa-xmark-circle:before {
        color: red;
    }

    .fa-solid,
    .fass {
        font-size: x-large;
    }

    .p-column-filter-menu-button,
    .p-column-filter-clear-button {
        visibility: hidden;
    }

    .p-dropdown-label {
        overflow: visible;
    }

}



#GateNowMap {
    min-width: 697px;

    .legend {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: Arial, sans-serif;
        font-size: 16px;
        z-index: 1000;
        max-width: 520px;
    }

    .legend-item {
        // width: 20px;
        // height: 20px;
        // display: inline-block;
        // border-radius: 5px;
        // vertical-align: middle;
        // margin-right: 10px;
        // border: 1px solid #000; /* Optional: adds a border to the color box */
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
        color: white;
        font-size: 1.2rem;
        padding: 2px 5px;
        min-height: 20px;
        opacity: 0.8;
        border: 2px solid #000;

        &.out-of-range {
            border: 3px dashed #000;
        }
    }

    .legend-text {
        display: inline;
        margin-right: 15px;
        vertical-align: middle;
    }

    .switch-text {
        display: inline-block;
        margin: 2px 4px 0 0;
        vertical-align: top;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .p-inputswitch {
        vertical-align: top;
        margin: 2px 0;
    }

    .mapboxgl-ctrl-bottom-right {
        display: none;
    }

    .mapboxgl-ctrl-bottom-left {
        display: none;
    }

    .mapboxgl-popup-content {

        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        text-align: center;
        font-size: small;
    }

    .acaa-marker {
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
        color: white;
        font-size: 1.2rem;
        padding: 2px 5px;
        min-height: 20px;
        opacity: 0.8;
        border: 2px solid black;

        &.out-of-range {
            border: 3px dashed #000;
        }
    }

    .acaa-marker-dashed {
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
        color: white;
        font-size: 1.2rem;
        padding: 2px 5px;
        min-height: 20px;
        opacity: 0.8;
        border: 3px dashed #000;
    }

    .triangle-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #4f4f4f;
        margin: auto;
        margin-top: 2px;
        position: absolute;
        bottom: -14px;
        right: 0px;
    }

    .triangle-down-white {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        //border-top: 10px solid #ecdfdf;
        border-top: 10px solid #4f4f4f;
        margin: auto;
        margin-top: 2px;
        position: absolute;
        bottom: -14px;
        right: 0px;
    }
}

.p-tabmenu .p-tabmenu-nav {
    border: 0px;
}

@media only screen and (max-width: 600px) {
    .tooldrop-header {
        display: inline-block;

        >* {
            margin-right: 0px;
            margin-bottom: 8px;
            max-width: 100%;
        }
    }
}